import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useEffect, useState } from "react";
import { useInvestment } from "./useInvestments";
import { useOfferingAnalytics } from "./useOfferingAnalytics";
import { useOpenOffering } from "./useOfferings";

import { validate } from "uuid";

export function useWorkflowStartup(slug, investmentId) {
  const [startUri, setStartUri] = useState(null);
  const { data: me } = useCurrentUser();

  const { data: offering, isError } = useOpenOffering(slug);
  const analytics = useOfferingAnalytics(offering);

  const isValidUuid = validate(investmentId);

  const {
    data: investment,
    isLoading,
    refetch: refetchInvestment,
  } = useInvestment(investmentId);

  useEffect(() => {
    const run = async () => {
      // Load offering, user, and investment in parallel
      let next = investment?.next;
      let step = null;

      if (!isLoading && investment && next) {
        // Don't try to route to these pages, they don't exist for the investor.
        if (
          next === "DILIGENCE" ||
          next === "COUNTERSIGN" ||
          next === "REFUND" ||
          next === "CLOSING"
        ) {
          step = "complete";
        } else {
          // Determine initial uri to load
          step = next.toLowerCase();
        }
      }

      setStartUri(step);
    };

    run();
  }, [isValidUuid, isLoading, investment?.next, investment]);

  return {
    offering,
    user: me,
    investment,
    setInvestment: () => {},
    refetchInvestment,
    startUri,
    isError,
    analytics,
  };
}
